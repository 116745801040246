<template>
  <b-modal
    id="add-custom-fields"
    ref="add-custom-fields"
    centered
    title="Custom Fields"
    ok-title="Save"
    cancel-variant="outline-secondary"
    :ok-disabled="isLoading"
    @show="loadData"
    @hidden="$emit('reset')"
    @ok="postCustomFields"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <validation-observer
        ref="groupCustomFieldsRules"
        tag="form"
      >
        <b-row>
          <b-col
            v-if="defaultCustomFields.length"
            sm="12"
            class="mb-2"
          >
            <span>Defaults Fields</span>
          </b-col>
          <group-custom-field-row
            v-for="(item, index) in defaultCustomFields"
            :key="item.frontID"
            :fields-data="item"
            :field-index="index"
          />

          <b-col
            v-if="uniqueCustomFields.length"
            sm="12"
            class="my-2"
          >
            <hr>
            <span>Additional Fields</span>
          </b-col>
          <template
            v-for="(item, index) in uniqueCustomFields"
          >
            <div
              :key="index"
              class="d-flex"
            >
              <!-- Field Label -->
              <b-col
                :key="index+'_label'"
                md="5"
              >
                <b-form-group
                  label="Field Name"
                  label-for="group_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="field_label"
                    rules="required"
                  >
                    <b-form-input
                      v-model="item.label"
                      type="text"
                      :class="{'is-invalid': errors.length > 0 }"
                      @change="item.label = $event.toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field Value -->
              <b-col
                :key="index+'_value'"
                md="5"
              >
                <b-form-group
                  label="Field Value"
                  label-for="field_value"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="field_value"
                    rules="required"
                  >
                    <b-form-input
                      v-model="item.value"
                      type="text"
                      :class="{'is-invalid': errors.length > 0 }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Remove Button -->
              <b-col
                sm="2"
                class="align-self-center"
              >
                <div
                  style="padding-top: 7px"
                >
                  <SquareIcon
                    size="21"
                    icon="Trash2Icon"
                    class="cursor-pointer"
                    @onIconClick="removeCustomFieldItem(index)"
                  />
                </div>
              </b-col>
            </div>

          </template>

          <b-col
            sm="12"
          >
            <b-button
              variant="outline-primary"
              class="d-flex align-items-center justify-content-center w-100 mt-2"
              @click="addNewFieldsRow"
            >
              <feather-icon
                icon="PlusIcon"
                size="18"
                class="mr-1"
              />
              Add Field
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
      <div
        v-if="validationCustomFieldsErrors.length"
        class="form-group"
      >
        <p
          v-for="(validationError, index) in validationCustomFieldsErrors"
          :key="index"
          class="text-center text-danger"
        >
          {{ validationError }}
        </p>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'

import store from '@/store'
import GroupCustomFieldRow from '@/views/admin/group/group-forms/components/GroupCustomFieldRowFromType.vue'
import SquareIcon from '@/views/ui/icon/SquareIcon.vue'

export default {
  name: 'EditGroupCustomFieldsModal',
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BOverlay,

    ValidationObserver,
    ValidationProvider,

    GroupCustomFieldRow,

    SquareIcon,
  },
  data() {
    return {
      fieldsCount: 0,
      defaultCustomFields: [],
      uniqueCustomFields: [],
      validationCustomFieldsErrors: [],
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      programId: 'app-group-details/getProgramId',
      groupId: 'app-group-details/getGroupId',
      groupTypeFieldsList: 'app-group-details/getGroupTypeFieldsList',
      groupCustomFieldsList: 'app-group-details/getGroupCustomFieldsList',
    }),
  },
  methods: {
    async loadData() {
      this.defaultCustomFields = this.groupTypeFieldsList
      this.uniqueCustomFields = this.groupCustomFieldsList
      this.fieldsCount = this.uniqueCustomFields.length

      const staffFields = this.defaultCustomFields.filter(field => field.type === 'staff')

      if (staffFields.length) {
        await this.$store.dispatch('app-group-details/fetchStaffList', {
          programId: this.programId,
        })
          .then(response => {
            const options = response.data.data.map(item => ({ id: item.user.id, label: item.user.full_name }))
            staffFields.forEach(item => {
              // eslint-disable-next-line no-param-reassign
              item.options = options
            })
          })
      }
    },
    isFieldKeyExists(key) {
      return this.uniqueCustomFields.some(field => field.name === `field_${key}`)
    },
    addNewFieldsRow() {
      do {
        this.fieldsCount += 1
      } while (this.isFieldKeyExists(this.uniqueCustomFields))

      this.uniqueCustomFields.push({
        id: null,
        frontID: this.fieldsCount,
        type: 'short_text',
        type_error: false,
        label: `Field ${this.fieldsCount}`,
        name: `field_${this.fieldsCount}`,
        required: false,
        options: [],
        default: '',
        delete: false,
        order: this.fieldsCount,
        subItems: [],
        newField: true,
        attribute_field_name: null,
      })
    },
    removeCustomFieldItem(index) {
      this.uniqueCustomFields.splice(index, 1)
    },
    async postCustomFields(bvModalEvt) {
      bvModalEvt.preventDefault()

      const payload = {
        queryParams: {
          fields: this.prepareCustomFieldsFromTypeFields(),
        },
        vm: this,
      }

      // eslint-disable-next-line consistent-return
      return new Promise(reject => {
        this.$refs.groupCustomFieldsRules.validate().then(success => {
          if (success) {
            this.isLoading = true

            store.dispatch('app-group-details/updateCustomFields', payload)
              .then(response => {
                if (response.status === 422) {
                  const validationErrors = Object.values(response.data.errors)
                  this.validationCustomFieldsErrors = validationErrors.flat()
                } else {
                  this.validationCustomFieldsErrors = []
                  this.$nextTick(() => {
                    this.$refs['add-custom-fields'].toggle('#toggle-btn')
                  })
                }
              })
              .finally(() => {
                this.isLoading = false
              })
          } else {
            reject()
          }
        })
      })
    },
    prepareCustomFieldsFromTypeFields() {
      const defaults = this.defaultCustomFields.map(row => ({
        label: row.label,
        attribute: row.name,
        value: row.value,
        group_type_custom_field_id: row.group_type_custom_field_id,
      }))

      const unique = this.uniqueCustomFields.map(row => ({
        label: row.label,
        attribute: row.attribute ? row.attribute : row.name,
        value: row.value,
      }))

      return defaults.concat(unique)
    },
  },
}
</script>

<style scoped>

</style>
