<template>
  <b-modal
    id="generate-badges"
    ref="generate-badges"
    title="Generate badges"
    centered
    size="sm"
    no-close-on-backdrop
    :ok-disabled="isLoading"
    @show="prepareData"
    @close="closeModal"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
    >
      <validation-observer
        ref="generateBadgesRules"
        tag="form"
      >
        <b-form>
          <b-form-group
            v-if="!showSuccessMessage"
            :label="'Badge color'"
            class="mb-2"
          >
            <validation-provider
              #default="{ errors }"
              :name="'Badge color'"
              rules="required"
            >
              <b-form-input
                v-model="selectedColor"
                placeholder="Color"
                class="mb-1"
              />

              <chrome-picker
                :value="selectedColor"
                class="w-100"
                :disable-alpha="true"
                :disable-fields="true"
                @input="handleColor"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            v-if="!showSuccessMessage"
            label="Export type"
          >
            <b-form-radio
              v-model="isSendByEmail"
              class="mb-1"
              name="exportType"
              :value="false"
            >
              Download file
            </b-form-radio>
            <b-form-radio
              v-model="isSendByEmail"
              name="exportType"
              :value="true"
            >
              Send file by email
            </b-form-radio>
          </b-form-group>
          <div
            v-if="!showSuccessMessage && isSendByEmail"
          >
            <hr>
            <h5
              class="text-center py-1"
            >
              This process may take a while. You will receive an email with Letters file when complete. Please enter email
              below.
            </h5>
            <b-form-group
              class="mb-2"
            >
              <!-- Email -->
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-group
                  label="Email to send"
                  label-for="email"
                >
                  <b-form-input
                    v-model="selectedEmail"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </div>

          <h5
            v-if="showSuccessMessage"
            class="text-center py-1"
          >
            The process may take a while. You will receive an email with generated badges file when complete.
          </h5>

        </b-form>
      </validation-observer>
    </b-overlay>

    <template #modal-footer>
      <template v-if="!showSuccessMessage">
        <b-button
          variant="outline-secondary"
          @click="closeModal"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="isLoading"
          @click="generate"
        >
          Generate
        </b-button>
      </template>
      <b-button
        v-else
        variant="primary"
        @click="closeModal"
      >
        Ok
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  VBModal,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
  BFormRadio,
} from 'bootstrap-vue'
import moment from 'moment'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { Chrome } from 'vue-color'

export default {
  components: {
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,

    'chrome-picker': Chrome,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [downloadExportFile],
  props: {
    groupId: {
      type: String,
      default: '',
    },
    programId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      isLoading: false,
      selectedColor: '#00ff00',
      showSuccessMessage: false,
      programGroups: [],
      selectedEmail: null,
      isSendByEmail: false,
      required,
    }
  },
  methods: {
    async prepareData() {
      this.selectedEmail = this.userData.email ?? ''
      this.isSendByEmail = false

      if (this.programId) {
        try {
          const queryParams = {
            exceptProgramType: 'Program',
            programId: this.programId,
          }
          const responce = await axios.get('/auth/groups', { params: queryParams })
          this.programGroups = responce.data.data
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching program groups',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    async closeModal() {
      this.selectedColor = '#00ff00'
      this.showSuccessMessage = false
      this.programGroups = []
      this.$refs['generate-badges'].hide()
      this.$emit('close')
    },
    handleColor(color) {
      this.selectedColor = color.hex
    },
    async generateBadges() {
      try {
        const queryParams = {
          badge_template_id: 1,
          send_by_email: this.isSendByEmail,
          selected_email: this.selectedEmail,
        }

        if (this.programId) {
          queryParams.groups = this.programGroups.map(group => ({
            group_id: group.id,
            background_color: this.selectedColor,
          }))
        } else {
          queryParams.groups = [
            {
              group_id: this.groupId,
              background_color: this.selectedColor,
            },
          ]
        }

        // await axios.post('/auth/generate-badges', queryParams, { responseType: 'blob' }).then(response => {
        //   if (response.status === 200) {
        //     this.downloadFile(response.data, `${this.group.name}(badges)`, 'zip')
        //     this.closeModal()
        //   }
        // })
        await axios.post('/auth/generate-badges', queryParams, { responseType: 'blob' })
          .then(response => {
            if (response.status === 200 && this.isSendByEmail === false) {
              this.downloadFile(response.data, `Generated Badges - ${moment().format('YYYY-MM-DD')}`, 'zip')
              this.closeModal()
            } else {
              this.showSuccessMessage = true
            }
          })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error generating badges',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async generate(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise(resolve => {
        this.$refs.generateBadgesRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.isLoading = true
            this.generateBadges().finally(() => {
              this.isLoading = false
            })
          }
        })
      })
    },
  },
}
</script>
