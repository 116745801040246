<template>
  <validation-observer
    ref="groupTypeLimitSelectionRule"
    tag="form"
  >
    <b-list-group-item
      tag="div"
      class="condition-area"
    >
      <b-row>
        <b-col cols="12">
          <h6 class="d-flex justify-content-between align-items-center">
            <span>
              Condition {{ index + 1 }}
            </span>
            <SquareIcon
              size="21"
              icon="Trash2Icon"
              class="cursor-pointer"
              @onIconClick="removeConditionItem"
            />
          </h6>
          <hr>
        </b-col>

        <b-col
          cols="2"
          align-self="center"
        >
          <span class="text-primary">When</span>
        </b-col>

        <b-col cols="10">
          <!-- Student PPA -->
          <b-form-group
            :label="`${camperStudent(1)} PPA`"
          >
            <validation-provider
              #default="{ errors }"
              :name="`${camperStudent(1)} PPA`"
              rules="required"
            >
              <v-select
                v-model="conditionData.studentPpa"
                class="bg-white"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="studentPpaList"
                :placeholder="'Select'"
                :clearable="false"
                :class="{'is-invalid': errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Operator -->
          <b-form-group
            label="Operator"
          >
            <validation-provider
              #default="{ errors }"
              name="Operator"
              rules="required"
            >
              <v-select
                v-model="conditionData.operator"
                class="bg-white"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="operatorsOptions"
                :placeholder="'Select'"
                :clearable="false"
                :reduce="option => option.value"
                :class="{'is-invalid': errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Custom Field  -->
          <b-form-group
            label="Custom Field"
          >
            <validation-provider
              #default="{ errors }"
              name="Custom Field"
              rules="required"
            >
              <v-select
                v-model="conditionData.customField"
                class="bg-white"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="customFieldsList"
                :placeholder="'Select'"
                :clearable="false"
                label="label"
                :reduce="option => option.label"
                :class="{'is-invalid': errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

      </b-row>
    </b-list-group-item>
  </validation-observer>
</template>

<script>
import {
  BCol,
  BRow,
  BFormGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import SquareIcon from '@/views/ui/icon/SquareIcon.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormGroup,
    BListGroupItem,

    SquareIcon,
    vSelect,
  },
  props: {
    conditionData: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    customFieldsList: {
      type: Array,
      required: true,
    },
    studentPpaList: {
      type: Array,
      required: true,
    },
    operatorsOptions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    removeConditionItem() {
      this.$emit('removeConditionItem', this.index)
    },
  },
}
</script>

<style scoped>
  .condition-area {
    background: #FBFBFC;
    border-radius: 5px;
  }
</style>
