import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCommunicationSetting() {
  // Use toast
  const toast = useToast()

  const sendCommunication = queryParams => axios
    .post('/auth/communications/send', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Communication was successfully send',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error sending communication',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchCommunicationTypes = () => axios
    .get('/auth/communication-types')
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Communication Types',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchAllEmailTemplatesList = () => axios
    .get('/auth/email-templates')
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Email Templates',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchAllSmsTemplatesList = () => axios
    .get('/auth/sms-templates')
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching SMS Templates',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    sendCommunication,
    fetchCommunicationTypes,
    fetchAllEmailTemplatesList,
    fetchAllSmsTemplatesList,
  }
}
