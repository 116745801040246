<template>
  <b-modal
    id="add-staff-to-group"
    ref="add-staff-to-group"
    ok-title="Add"
    cancel-variant="outline-secondary"
    centered
    title="Add Staff"
    no-close-on-backdrop
    :ok-disabled="isLoading"
    @show="prepareData"
    @close="closeModal"
    @ok="setStaffToGroup"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <validation-observer
        ref="addStaffToGroupRules"
        tag="form"
      >
        <b-form>
          <b-form-group
            label="Staff"
          >
            <validation-provider
              #default="{ errors }"
              name="staff"
              rules="required"
            >
              <v-select
                v-model="chosenMember"
                :options="staffList"
                :filterable="false"
                label="text"
                value="value"
                :placeholder="staffFieldStatus"
                :class="{'is-invalid': errors.length > 0 }"
                @search="onSearch"
              >
                <li
                  v-if="metaData.current_page"
                  slot="list-footer"
                  class="pagination d-flex align-items-center justify-content-between my-1 mx-2"
                >
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-primary"
                    :disabled="!hasPrevPage"
                    @click="currentPage--"
                  >
                    Prev
                  </b-button>
                  <span>{{ currentPage }} of {{ metaData.last_page }}</span>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-primary"
                    :disabled="!hasNextPage"
                    @click="currentPage++"
                  >
                    Next
                  </b-button>
                </li>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  VBModal, BOverlay, BModal, BButton, BFormGroup, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'AddStaffToGroupModal',
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BModal,
    BButton,
    BFormGroup,
    BForm,

    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    groupId: {
      type: String,
      required: true,
    },
    programId: {
      type: String,
      required: true,
    },
    sessionsList: {
      type: Array,
      default: () => [],
    },
    validationErrors: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      staffFieldStatus: 'Loading...',
      chosenMember: null,
      currentPage: 1,
      searchQuery: null,
      staffList: [],
      metaData: {},
      required,
    }
  },
  computed: {
    hasNextPage() {
      return this.metaData.current_page !== this.metaData.last_page
    },
    hasPrevPage() {
      return this.currentPage > 1
    },
  },
  watch: {
    currentPage() {
      this.fetchingStaffForAdd()
    },
    searchQuery() {
      this.fetchingStaffForAdd()
    },
  },
  methods: {
    async prepareData() {
      this.chosenMember = null
      this.currentPage = 1
      this.searchQuery = null
      this.metaData = {}
      this.staffList = []
      await this.fetchingStaffForAdd()
    },
    async fetchingStaffForAdd() {
      const payloadData = {
        exceptGroupId: this.groupId,
        perPage: 25,
        page: this.currentPage,
        programId: this.programId,
        sortBy: 'name',
        sortDesc: false,
      }
      if (this.searchQuery) payloadData.name = this.searchQuery
      await this.$store.dispatch('app-group-details/fetchStaffList', payloadData)
        .then(response => {
          this.staffList = response.data.data.map(item => ({ value: item.user.id, text: item.user.full_name }))
          this.staffFieldStatus = 'Select Staff'
          this.metaData = response.data.meta
        })
    },
    async onSearch(query) {
      this.searchQuery = query
      this.currentPage = 1
    },
    async closeModal() {
      this.$refs['add-staff-to-group'].hide()
    },
    async setStaffToGroup(bvModalEvt) {
      bvModalEvt.preventDefault()

      // eslint-disable-next-line consistent-return
      return new Promise(() => {
        this.$refs.addStaffToGroupRules.validate().then(success => {
          if (success) {
            this.$emit('ok', { member: this.chosenMember })
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">

</style>
