<template>
  <ValidationObserver
    ref="bulk-adding-charge"
  >
    <b-modal
      id="bulk-adding-charge-modal"
      ref="bulk-adding-charge-modal"
      centered
      :title="'Adding Charge (' + totalLength + ' selected)'"
      ok-title="Save"
      ok-only
      no-close-on-backdrop
      @hidden="closeModal"
      @show="prepareData"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-row>
          <b-col
            v-if="withExecute"
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Field with available application ID"
            >
              <validation-provider
                #default="{ errors }"
                name="destination field"
                rules="required"
              >
                <v-select
                  v-model="targetField"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="destinationTypeOptions"
                  :reduce="option => option.value"
                  :clearable="false"
                  :placeholder="'Select Field'"
                  :disabled="!destinationTypeOptions.length"
                  :class="{'is-invalid': errors.length > 0 }"
                  @input="handleTargetIdSelected"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Charge List"
            >
              <v-select
                v-model="selectedFee"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="programFeesListOptions"
                label="text"
                :clearable="false"
                :reduce="val => val.value"
                :placeholder="'Select Fee'"
                :disabled="!targetField && withExecute"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="isNewFeeCreating || feeId"
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Fee Name"
            >
              <validation-provider
                #default="{ errors }"
                name="fee name"
                rules="required"
              >
                <b-form-input
                  v-model="feeName"
                  type="text"
                  :disabled="!!feeId"
                  :placeholder="'Name'"
                  :class="{'is-invalid': errors.length > 0 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="isNewFeeCreating || feeId"
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Fee Cost"
            >
              <validation-provider
                #default="{ errors }"
                name="fee cost"
                rules="required"
              >
                <b-form-input
                  v-model="feeCost"
                  type="number"
                  :disabled="!!feeId"
                  :placeholder="'$ Cost'"
                  :class="{'is-invalid': errors.length > 0 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="isNewFeeCreating"
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Date"
            >
              <validation-provider
                #default="{ errors }"
                name="Date"
                rules="required"
              >
                <flat-pickr
                  v-model="feeDate"
                  class="form-control"
                  :placeholder="'Date'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>

      <template #modal-footer>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          :disabled="isLoading"
          @click="closeModal"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          :disabled="isLoading || !feeCost || !feeName"
          @click="handleForm"
        >
          Save
        </b-button>
      </template>
    </b-modal>

    <confirmation-modal
      :type="'bulkCharge'"
      :toggle-modal="confirmationModalShow"
      :title="'Are you sure'"
      :message="`Are you sure you want to charge $${feeCost} for ${totalLength} contacts?`"
      @close-modal="confirmationModalShow = !confirmationModalShow"
      @confirm="createCharge"
    />
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BOverlay,
  BButton,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useAddingChargeModal from '@/views/components/adding-charge-modal/useAddingChargeModal'
import useReportDetails from '@/views/admin/reports-v2/reports/report-details/useReportDetails'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import moment from '@/libs/moment'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BOverlay,
    BButton,
    BFormInput,
    vSelect,
    flatPickr,
    ConfirmationModal,
  },
  directives: {
    Ripple,
  },
  props: {
    markedElements: {
      type: Array,
      required: true,
    },
    fieldsOptions: {
      type: [Array, Object],
      default: () => {},
    },
    executeData: {
      type: Object,
      default: () => {},
    },
    allElementsChecked: {
      type: Boolean,
      default: () => false,
    },
    withExecute: {
      type: Boolean,
      default: () => false,
    },
    filterName: {
      type: String,
      default: null,
    },
    programId: {
      type: String,
      default: null,
    },
    totalElementsCount: {
      type: Number,
      default: () => 0,
    },
    groupId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedFee: {},
      feeId: null,
      feeCost: null,
      feeName: null,
      feeDate: null,
      isNewFeeCreating: null,
      programFeesListOptions: [],
      isLoading: false,
      destinationTypeOptions: [],
      targetField: null,
      selectedApplications: [],
      totalLength: 0,
      confirmationModalShow: false,
      required,
    }
  },
  watch: {
    selectedFee(val) {
      if (val != null) {
        this.isNewFeeCreating = false
        this.feeId = val.id
        this.feeCost = val.cost
        this.feeName = val.name
      } else {
        this.isNewFeeCreating = true
        this.feeId = null
        this.feeCost = null
        this.feeName = null
        this.feeDate = null
      }
    },
  },
  setup() {
    const {
      fetchFees,
      applyFee,
    } = useAddingChargeModal()

    const {
      fetchResultsByTargetField,
    } = useReportDetails()

    return {
      fetchFees,
      applyFee,
      fetchResultsByTargetField,
    }
  },
  methods: {
    async elementsCount() {
      this.totalLength = this.allElementsChecked ? this.totalElementsCount : this.markedElements.length
    },
    closeModal() {
      this.$bvModal.hide('confirmation')
      this.selectedFee = {}
      this.feeId = null
      this.feeCost = null
      this.feeName = null
      this.feeDate = null
      this.isNewFeeCreating = false
      this.destinationTypeOptions = []
      this.targetField = null
      this.$refs['bulk-adding-charge-modal'].hide()
    },
    async prepareData() {
      if (this.withExecute) {
        this.destinationTypeOptions = this.fieldsOptions
      }
      await this.prepareFeesList()
      await this.elementsCount()
    },
    async handleTargetIdSelected() {
      if (this.allElementsChecked) {
        const queryParams = {
          executeData: this.executeData,
          targetField: this.targetField,
        }
        if (this.programId) {
          queryParams.program_id = this.programId
        }
        this.selectedApplications = await this.fetchResultsByTargetField(queryParams)
          .then(response => response.data)
      } else {
        this.selectedApplications = this.markedElements.map(element => element[this.targetField])
      }
      await this.elementsCount()
    },
    async prepareFeesList() {
      const queryParam = {
        has_program: true,
      }
      if (this.allElementsChecked && this.groupId) {
        queryParam.group_id = this.groupId
      } else if (this.filterName && !this.withExecute) {
        queryParam[this.filterName] = this.markedElements
      }
      this.programFeesListOptions = await this.fetchFees(queryParam)
        .then(response => response.reduce((arr, item) => {
          arr.push({ value: item, text: item.name })
          return arr
        }, []))
      if (this.$can('create', permissionSubjects.CustomCharge)) {
        this.programFeesListOptions.push({ value: null, text: 'Create a Custom One' })
      }
    },
    handleForm() {
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs['bulk-adding-charge'].validate().then(success => {
          if (success) {
            resolve(true)
            this.confirmationModalShow = true
          } else {
            reject()
          }
        })
      })
    },
    async createCharge() {
      this.confirmationModalShow = !this.confirmationModalShow
      const queryParams = {
        fee_cost: this.feeCost,
        fee_name: this.feeName,
        fee_date: this.prepareDateToUtc(this.feeDate),
      }
      if (this.programId) {
        queryParams.program_id = this.programId
      }
      if (this.feeId) {
        queryParams.fee_id = this.feeId
      }
      if (this.allElementsChecked && this.groupId) {
        queryParams.group_id = this.groupId
      } else if (this.filterName) {
        queryParams[this.filterName] = this.withExecute ? this.selectedApplications : this.markedElements
      }
      this.isLoading = true
      await this.applyFee(queryParams)
        .then(() => {
          this.$emit('success')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    prepareDateToUtc(date) {
      return moment.utc(moment(date).format('YYYY-MM-DDTHH:mmZ')).format('YYYY-MM-DD HH:mm')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
