<template>
  <b-form-group
    label="Available placeholder for body"
    class="mb-2"
  >
    <b-dropdown
      id="parent-dropdown"
      ref="parentDropdown"
      v-click-outside="hideParentDropdown"
      class="mb-1 parent-dropdown"
      @show="showParent"
      @hide="hideParent"
    >
      <template #button-content>
        <b-form-input
          id="dropdown-search-input"
          ref="dropdownSearchInput"
          v-model="selectedPlaceholder.label"
          placeholder="Select placeholder"
          @click="hideDropdown"
        />
      </template>
      <template v-for="item in filteredItems">
        <div
          :key="item.id"
        >
          <b-dropdown-item
            v-if="item.type === 'form' || item.type === 'wallet'"
            :class="{ active: isActive(item.id) }"
            @click="showChild(item)"
          >
            <div class="dropdown-form-item">
              <span class="menu-item-label">{{ item.label }}</span>
              <b-badge
                class="mr-2"
                pill
                :variant="getColor(item.type)"
              >
                {{ item.type }}
              </b-badge>
            </div>
            <b-icon-chevron-right
              font-scale=".9"
              variant="light-primary"
            />
          </b-dropdown-item>
          <b-dropdown-item
            v-else
            class="d-flex justify-content-between"
            @click="selectPlaceholder(item)"
          >
            <span class="menu-item-label">{{ item.label }}</span>
            <b-badge
              class="mr-2"
              pill
              :variant="getColor(item.type)"
            >
              {{ item.type }}
            </b-badge>
          </b-dropdown-item>
        </div>
      </template>
    </b-dropdown>

    <b-dropdown
      v-show="isChildDropdownOpened && childDropdownItem"
      ref="childDropdown"
      class="child-dropdown"
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
      dropdown
      @click.native.stop
      @hide="hideChild"
    >
      <div class="m-1">
        <div class="menu-item-label h6">
          {{ childDropdownItem.label }}
        </div>
        <b-form-input
          ref="childSearch"
          v-model="childSearch"
          class="child-search mt-1"
          placeholder="Type field name"
        />
      </div>

      <template v-for="el in childDropdownItem.fields">
        <b-dropdown-item
          v-if="showChildField(el)"
          :key="el.id"
          @click="selectPlaceholder(el)"
        >
          <span class="menu-item-label">{{ el.label }}</span>
        </b-dropdown-item>
      </template>
    </b-dropdown>
  </b-form-group>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { nextTick } from '@vue/composition-api'
import {
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BIconChevronRight,
} from 'bootstrap-vue'

import vClickOutside from 'v-click-outside'
import useAvailablePlaceholder from '@/views/components/available-placeholder/useAvailablePlaceholder'

Vue.use(vClickOutside)
export default {
  components: {
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BIconChevronRight,
  },
  props: {
    defaultPlaceholders: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      childDropdownItem: {},
      childDropdownItemId: null,
      childSearch: '',
      formFields: [],
      isChildDropdownOpened: false,
      placeholders: [],
      groupTypes: [],
      selectedPlaceholder: {
        label: '',
      },
      uniqueFields: [],
      wallets: [],
    }
  },
  computed: {
    ...mapGetters({
      programId: 'verticalMenu/getDefaultProgram',
    }),
    allPlaceholders() {
      return [...this.uniqueFields, ...this.placeholders, ...this.formFields, ...this.groupTypes, this.wallets]
    },
    filteredItems() {
      if (this.defaultPlaceholders.length) {
        return this.defaultPlaceholders
      }
      const search = this.selectedPlaceholder ? this.selectedPlaceholder.label?.toLowerCase() : ''
      return this.allPlaceholders.filter(item => item.label?.toLowerCase().includes(search))
    },
  },
  setup() {
    const {
      fetchUniqueFields,
      fetchPlaceholders,
      fetchFormFields,
      fetchWallets,
      fetchGroupTypesForSelect,
    } = useAvailablePlaceholder()

    return {
      fetchUniqueFields,
      fetchPlaceholders,
      fetchFormFields,
      fetchWallets,
      fetchGroupTypesForSelect,
    }
  },
  watch: {
    'selectedPlaceholder.label': function fn() {
      if (this.filteredItems.length === 0) {
        this.$refs.parentDropdown.hide()
      } else {
        this.$refs.parentDropdown.show()
      }
    },
  },
  async mounted() {
    if (this.defaultPlaceholders.length) {
      return
    }

    const attributes = await this.fetchUniqueFields()
    this.uniqueFields = attributes.map(item => ({
      type: 'dynamic',
      value: `{{_${item}}}`,
      label: item,
    }))

    const formFields = await this.fetchFormFields()
    this.formFields = formFields.data.map(item => {
      const fields = item.fields.map(el => ({
        type: 'form',
        value: `{{_${el.name}}}`,
        label: el.name,
        id: el.id,
      }))
      return {
        type: 'form',
        value: item.name,
        label: item.name,
        id: item.id,
        fields,
      }
    })

    const placeholders = await this.fetchPlaceholders()
    this.placeholders = placeholders.map(item => ({
      type: 'fixed',
      value: `{{_${item}}}`,
      label: item,
    }))

    const queryParams = {
      program_id: this.programId,
      exceptProgramType: 'Program',
    }
    const groupTypes = await this.fetchGroupTypesForSelect(queryParams)
    this.groupTypes = groupTypes.map(item => ({
      type: 'group type',
      value: `{{group_type:${item.text}:${item.value}}}`,
      label: item.text,
    }))

    const wallets = await this.fetchWallets()

    this.wallets = {
      type: 'wallet',
      value: 'Wallet amount',
      label: 'Wallet amount',
      fields: wallets.map(el => ({
        type: 'wallet',
        value: `{{wallet_amount:${el.name}}}`,
        label: el.name,
        id: el.id,
      })),
    }
  },
  methods: {
    getColor(type) {
      switch (type) {
        case 'fixed':
          return 'light-primary'

        case 'form':
          return 'light-warning'

        case 'wallet':
          return 'light-success'

        case 'group type':
          return 'light-danger'

        default:
          return 'light-info'
      }
    },
    hideChild() {
      this.childDropdownItem = {}
      if (!this.isChildDropdownOpened) {
        this.$refs.parentDropdown.hide()
      }
    },
    async hideDropdown() {
      this.selectedPlaceholder = {
        label: '',
      }
      await nextTick()
      this.$refs.dropdownSearchInput.focus()
    },
    hideParent(e) {
      if (this.isChildDropdownOpened) {
        e.preventDefault()
      }
    },
    hideParentDropdown() {
      this.$refs.parentDropdown.hide()
    },
    insertValueToEditor() {
      this.$emit('insertValueToEditor', this.selectedPlaceholder.value)
    },
    isActive(id) {
      return id === this.childDropdownItem.id
    },
    showChild(item) {
      if (item.id !== this.childDropdownItemId
        || (item.id === this.childDropdownItemId && !this.isChildDropdownOpened)
      ) {
        this.childDropdownItem = item
        this.isChildDropdownOpened = true
        this.childSearch = ''
        this.$refs.childDropdown.show()
        setTimeout(() => {
          this.$refs.childSearch.focus()
        }, 0)
      } else {
        this.isChildDropdownOpened = false
      }
      this.childDropdownItemId = item.id
    },
    selectPlaceholder(item) {
      this.selectedPlaceholder = item
      this.isChildDropdownOpened = false
      this.insertValueToEditor()
      this.hideDropdown()
    },
    showChildField(field) {
      return field.label?.toLowerCase().includes(this.childSearch?.toLowerCase())
    },
    async showParent() {
      await nextTick()
      const ul = document.getElementById('parent-dropdown').getElementsByTagName('ul')[0]
      ul.scrollTop = 0
      this.$refs.dropdownSearchInput.focus()
    },
  },
}
</script>
