import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useAddingChargeModal() {
  // Use toast
  const toast = useToast()

  const fetchFees = queryParams => axios
    .get('auth/fees', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Fees',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const applyFee = queryParams => axios
    .post('auth/fees/apply-fee', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Fee was successfully applied',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error applying Fee',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error
    })

  const postFee = queryParams => axios
    .post('/auth/fees', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Fee was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error creating Fee',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error
    })
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchFees,
    applyFee,
    postFee,
  }
}
