import { computed, ref, watch } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'
import router from '@/router'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useGroupMembers(root) {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)

  // Table Handlers
  const defaultTableColumns = [
    {
      key: 'selected', label: 'selected', selected: true, sortable: false,
    },
    { key: 'first_name', label: 'First Name', sortable: false },
    { key: 'last_name', label: 'Last Name', sortable: false },
    { key: 'age', label: 'Age', sortable: false },
    { key: 'role', label: 'Role', sortable: false },
  ]
  const tableColumns = ref([])
  const tableItems = ref([])
  const isTableLoading = ref(false)
  const perPage = ref(DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const filterDataSession = ref('')
  const filterDataTag = ref([])
  let debounceTimeout = ref(null)

  const setTableColumns = data => {
    if (data[0]) {
      const ppaColumns = Object.keys(data[0]).filter(item => item.includes('ppa'))
      tableColumns.value = [...defaultTableColumns, ...ppaColumns]
      tableColumns.value.push('actions')
    } else {
      tableColumns.value = [...defaultTableColumns]
    }
  }
  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])
  const localStorageItemName = computed(() => `groupPpaColumns-${programId.value}`)

  const refetchData = () => {
    // eslint-disable-next-line no-use-before-define
    fetchMembersList()
  }

  watch([currentPage, perPage, searchQuery, filterDataSession, filterDataTag], () => {
    refetchData()
  })
  watch([currentPage, perPage, searchQuery, filterDataSession, filterDataTag],
    ([currentPageVal, perPageVal, searchQueryVal, filterDataSessionVal, filterDataTagVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal, filterDataSessionPrevVal, filterDataTagPrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'groups-detail', 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'groups-detail', 'perPage', perPageVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'groups-detail', 'searchQuery', searchQueryVal)
        }, 250)
      }
      if (filterDataSessionVal !== filterDataSessionPrevVal) {
        pushStateFiltersParams(root, 'groups-detail', 'filterDataSession', filterDataSessionVal)
      }
      if (filterDataTagVal !== filterDataTagPrevVal) {
        pushStateFiltersParams(root, 'groups-detail', 'filterDataTag', filterDataTagVal)
      }
    })

  const fetchMembersList = () => {
    isTableLoading.value = true
    const payloadData = {
      groupId: router.currentRoute.params.id,
      perPage: perPage.value,
      page: currentPage.value,
      pageType: 'group',
      ppa: JSON.parse(localStorage.getItem(localStorageItemName.value)),
    }
    if (searchQuery.value) payloadData.userName = searchQuery.value
    if (filterDataSession.value) payloadData.sessionId = filterDataSession.value
    if (filterDataTag.value) payloadData.tagId = filterDataTag.value
    store
      .dispatch('app-group-details/fetchAllMemberList', payloadData)
      .then(response => {
        const { data } = response.data
        setTableColumns(data)
        tableItems.value = data
        const { total, from, to } = response.data.meta
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching campers' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        isTableLoading.value = false
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchMembersList,
    tableColumns,
    tableItems,
    isTableLoading,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    totalItems,
    refInvoiceListTable,

    filterDataSession,
    filterDataTag,

    refetchData,
  }
}
