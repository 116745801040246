<template>
  <ValidationObserver
    ref="bulkGenerateLetterModal"
  >
    <b-modal
      id="bulk-generate-letter-modal"
      ref="bulk-generate-letter-modal"
      centered
      :title="'Bulk Generate Letter (' + totalLength + ' selected)'"
      ok-only
      no-close-on-backdrop
      @hidden="closeModal"
      @show="prepareData"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-row v-if="!showSuccessMessage">
          <b-col
            v-if="withExecute"
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Field with available application ID"
            >
              <validation-provider
                #default="{ errors }"
                name="destination field"
                rules="required"
              >
                <v-select
                  v-model="targetField"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="destinationTypeOptions"
                  :reduce="option => option.value"
                  :clearable="false"
                  :placeholder="'Select Field'"
                  :disabled="!destinationTypeOptions.length"
                  :class="{'is-invalid': errors.length > 0 }"
                  @input="handleTargetIdSelected"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="12"
            class="mb-1"
          >
            <b-form-group
              label="Letter Template"
            >
              <validation-provider
                #default="{ errors }"
                name="letter template"
                rules="required"
              >
                <v-select
                  v-model="selectedLetter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="letterTemplatesOptions"
                  label="name"
                  value="id"
                  :clearable="false"
                  :placeholder="'Select letter'"
                  :disabled="!targetField && withExecute"
                  :class="{'is-invalid': errors.length > 0 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group
          v-if="!showSuccessMessage"
          label="Export type"
        >
          <b-form-radio
            v-model="isSendByEmail"
            class="mb-1"
            name="exportType"
            :value="false"
          >
            Download file
          </b-form-radio>
          <b-form-radio
            v-model="isSendByEmail"
            name="exportType"
            :value="true"
          >
            Send file by email
          </b-form-radio>
        </b-form-group>
        <div
          v-if="!showSuccessMessage && isSendByEmail"
        >
          <hr>
          <h5
            class="text-center py-1"
          >
            This process may take a while. You will receive an email with Letters file when complete. Please enter email
            below.
          </h5>
          <b-form-group
            class="mb-2"
          >
            <!-- Email -->
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email to send"
                label-for="email"
              >
                <b-form-input
                  v-model="selectedEmail"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-form-group>
        </div>

        <h5
          v-if="showSuccessMessage"
          class="text-center py-1"
        >
          The process may take a while. You will receive an email with Letters on your email as soon as done.
        </h5>
      </b-overlay>

      <template #modal-footer>
        <template v-if="!showSuccessMessage">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            :disabled="isLoading"
            @click="closeModal"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="float-right"
            :disabled="isLoading || !selectedLetter"
            @click="handleForm"
          >
            Generate
          </b-button>
        </template>
        <template v-else>
          <b-button
            variant="primary"
            class="float-right"
            @click="$emit('success')"
          >
            Ok
          </b-button>
        </template>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BOverlay,
  BButton,
  BFormRadio,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from 'moment/moment'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useBulkGenerateLetter from '@/views/components/bulk-generate-letter/useBulkGenerateLetter'
import useReportDetails from '@/views/admin/reports-v2/reports/report-details/useReportDetails'

export default {
  components: {
    BFormInput,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BOverlay,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [downloadExportFile],
  props: {
    markedElements: {
      type: Array,
      required: true,
    },
    fieldsOptions: {
      type: [Array, Object],
      default: () => {},
    },
    executeData: {
      type: Object,
      default: () => {},
    },
    allElementsChecked: {
      type: Boolean,
      default: () => false,
    },
    withExecute: {
      type: Boolean,
      default: () => false,
    },
    filterName: {
      type: String,
      default: null,
    },
    programName: {
      type: String,
      default: null,
    },
    totalElementsCount: {
      type: Number,
      default: () => 0,
    },
    groupId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      selectedLetter: null,
      selectedProgram: null,
      letterTemplatesOptions: [],
      programsOptions: [],
      totalLength: 0,
      destinationTypeOptions: [],
      targetField: null,
      selectedApplications: [],
      isLoading: false,
      showSuccessMessage: false,
      selectedEmail: null,
      isSendByEmail: false,
      required,
    }
  },
  setup() {
    const {
      programId,
      fetchProgram,
      fetchProgramList,
      fetchLetterTemplates,
      generateUsersLetters,
    } = useBulkGenerateLetter()

    const {
      fetchResultsByTargetField,
    } = useReportDetails()

    return {
      programId,
      fetchProgram,
      fetchProgramList,
      fetchLetterTemplates,
      generateUsersLetters,
      fetchResultsByTargetField,
    }
  },
  methods: {
    async elementsCount() {
      this.totalLength = this.allElementsChecked ? this.totalElementsCount : this.markedElements.length
    },
    closeModal() {
      this.selectedLetter = null
      this.selectedProgram = null
      this.destinationTypeOptions = []
      this.targetField = null
      this.selectedApplications = []
      this.showSuccessMessage = false
      this.$refs['bulk-generate-letter-modal'].hide()
    },
    async prepareData() {
      this.selectedEmail = this.userData.email ?? ''
      this.isSendByEmail = false

      if (this.withExecute) {
        this.destinationTypeOptions = this.fieldsOptions
      }
      await this.fetchLetterTemplates().then(response => {
        this.letterTemplatesOptions = response
      })

      this.elementsCount()

      if (!this.withExecute && !this.programName) {
        this.fetchProgramData()
      }
    },
    async prepareProgramsList() {
      const queryParams = {}
      if (this.filterName) {
        queryParams[this.filterName] = this.withExecute ? this.selectedApplications : this.markedElements
      }
      await this.fetchProgramList(queryParams).then(response => {
        this.programsOptions = response
        if (this.programsOptions.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          this.selectedProgram = this.programsOptions[0]
        }
      })
    },
    async fetchProgramData() {
      const response = await this.fetchProgram(this.programId)
      this.selectedProgram = response
    },
    async handleTargetIdSelected() {
      if (this.allElementsChecked) {
        const queryParams = {
          executeData: this.executeData,
          targetField: this.targetField,
        }
        this.selectedApplications = await this.fetchResultsByTargetField(queryParams)
          .then(response => response.data)
      } else {
        this.selectedApplications = this.markedElements.map(element => element[this.targetField])
      }
      await this.elementsCount()
    },
    handleForm() {
      return new Promise((resolve, reject) => {
        this.$refs.bulkGenerateLetterModal.validate().then(success => {
          if (success) {
            resolve(true)
            this.generateLetters()
          } else {
            reject()
          }
        })
      })
    },
    async generateLetters() {
      this.isLoading = true
      const queryParams = {
        letter_template_id: this.selectedLetter.id,
        send_by_email: this.isSendByEmail,
        selected_email: this.selectedEmail,
      }

      if (this.allElementsChecked && this.groupId) {
        queryParams.group_id = this.groupId
      } else if (this.filterName) {
        queryParams[this.filterName] = this.withExecute ? this.selectedApplications : this.markedElements
      }

      await this.generateUsersLetters(queryParams)
        .then(response => {
          if (response.status === 200) {
            if (response.status === 200 && this.isSendByEmail === false) {
              this.downloadFile(response.data, `${this.selectedLetter.name}(Generated Letters)-${moment().format('YYYY-MM-DD')}`, 'zip')
              this.$bvModal.hide('bulk-generate-letter-modal')
            } else {
              this.showSuccessMessage = true
            }
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
