<template>
  <b-modal
    id="select-ppa-columns"
    ref="select-ppa-columns"
    title="Select PPA Columns"
    centered
    no-close-on-backdrop
    ok-title="Apply"
    cancel-variant="outline-secondary"
    size="sm"
    class="p-0"
    @show="setModalData"
    @ok="savePpaColumns"
  >
    <b-form-group
      label="PPA Columns"
      class="mb-2"
    >
      <v-select
        v-model="selectedPpa"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="ppaList"
        multiple
        :close-on-select="false"
        placeholder="Select"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
} from 'bootstrap-vue'

import vSelect from 'vue-select'

export default {
  name: 'SelectPpaColumns',
  components: {
    BModal,
    BFormGroup,

    vSelect,
  },
  props: {
    ppaList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedPpa: [],
    }
  },
  computed: {
    programId() {
      return this.$store.getters['verticalMenu/getDefaultProgram']
    },
    localStorageItemName() {
      return `groupPpaColumns-${this.programId}`
    },
  },
  methods: {
    setModalData() {
      const localStorageColumns = JSON.parse(localStorage.getItem(this.localStorageItemName))
      if (localStorageColumns) {
        this.selectedPpa = localStorageColumns
      } else {
        this.selectedPpa = []
      }
    },
    savePpaColumns() {
      localStorage.setItem(this.localStorageItemName, JSON.stringify(this.selectedPpa))
      this.$emit('updateData')
    },
  },
}
</script>

<style>

</style>
