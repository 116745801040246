export const formBuilderPreparations = {

  methods: {
    designateOptions(type, options) {
      if (type === 'dropdown') {
        return options.reduce((arr, item) => {
          arr.push({ value: item, text: item })
          return arr
        }, [{ value: null, text: 'Select option' }])
      }

      return options
    },
    designateValue(type, def) {
      if (def && def !== 'not_accepted') {
        if (type === 'checkbox' || type === 'multi_select') return [def]

        return def
      }

      if (type === 'checkbox' || type === 'multi_select' || type === 'file_upload') return []
      if (type === 'dropdown') return null

      return null
    },
    transformField(type) {
      switch (type) {
        case 'short_text':
          return 'text'
        case 'phone':
          return 'tel'
        default:
          return type
      }
    },
  },
}

export const _ = null
