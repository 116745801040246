<template>
  <b-modal
    id="add-camper-to-group"
    ref="add-camper-to-group"
    cancel-variant="outline-secondary"
    centered
    :title="`Add ${camperStudent(1)}`"
    no-close-on-backdrop
    @show="prepareData"
    @close="closeModal"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <validation-observer
        ref="addCamperToGroupRules"
        tag="form"
      >
        <b-form>
          <b-form-group
            :label="camperStudent(1)"
          >
            <validation-provider
              #default="{ errors }"
              :name="camperStudent(1)"
              rules="required"
            >
              <v-select
                v-model="chosenMember"
                :options="campersList"
                :filterable="false"
                label="text"
                value="value"
                :placeholder="campersFieldStatus"
                :class="{'is-invalid': errors.length > 0 }"
                @search="onSearch"
              >
                <li
                  v-if="metaData.current_page"
                  slot="list-footer"
                  class="pagination d-flex  align-items-center justify-content-between my-1 mx-2"
                >
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-primary"
                    :disabled="!hasPrevPage"
                    @click="currentPage--"
                  >
                    Prev
                  </b-button>
                  <span>{{ currentPage }} of {{ metaData.last_page }}</span>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-primary"
                    :disabled="!hasNextPage"
                    @click="currentPage++"
                  >
                    Next
                  </b-button>
                </li>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            :label="sessionSemester(2)"
          >
            <validation-provider
              #default="{ errors }"
              :name="sessionSemester(2)"
              rules="required"
            >
              <v-select
                v-model="chosenSessions"
                :options="sessionsList"
                label="name"
                value="id"
                multiple
                :clearable="false"
                :placeholder="`Select ${sessionSemester(2)}`"
                :class="{'is-invalid': errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <div
        v-if="validationErrors.length"
        class="form-group pt-1"
      >
        <p
          v-for="(validationError, index) in validationErrors"
          :key="index"
          class="text-center text-danger"
        >
          {{ validationError }}
        </p>
      </div>
    </b-overlay>

    <template #modal-footer>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        @click="closeModal"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        class="float-right"
        :disabled="isLoading"
        @click="setCamperToGroup"
      >
        Add
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  VBModal, BOverlay, BModal, BButton, BFormGroup, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'AddCamperToGroupModal',
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BModal,
    BButton,
    BFormGroup,
    BForm,

    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    groupId: {
      type: String,
      required: true,
    },
    programId: {
      type: String,
      required: true,
    },
    sessionsList: {
      type: Array,
      required: true,
    },
    validationErrors: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      campersFieldStatus: 'Loading...',
      chosenMember: null,
      chosenSessions: null,
      currentPage: 1,
      searchQuery: null,
      campersList: [],
      metaData: {},
      required,
    }
  },
  computed: {
    hasNextPage() {
      return this.metaData.current_page !== this.metaData.last_page
    },
    hasPrevPage() {
      return this.currentPage > 1
    },
  },
  watch: {
    currentPage() {
      this.fetchingCampersForAdd()
    },
    searchQuery() {
      this.fetchingCampersForAdd()
    },
  },
  methods: {
    async prepareData() {
      this.chosenMember = null
      this.chosenSessions = null
      this.currentPage = 1
      this.searchQuery = null
      this.metaData = {}
      this.campersList = []
      await this.fetchingCampersForAdd()
    },
    async fetchingCampersForAdd() {
      const payloadData = {
        exceptGroupId: this.groupId,
        programId: this.programId,
        perPage: 25,
        page: this.currentPage,
        sortBy: 'name',
        sortDesc: false,
      }
      if (this.searchQuery) payloadData.name = this.searchQuery
      await this.$store.dispatch('app-group-details/fetchCampersForSelectList', payloadData)
        .then(response => {
          this.campersList = response.data.data.map(item => ({ value: item.application_id, text: item.full_name, id: item.id }))
          this.metaData = response.data.meta
          this.campersFieldStatus = `Select ${this.camperStudent(1)}`
        })
    },
    async onSearch(query) {
      this.searchQuery = query
      this.currentPage = 1
    },
    async closeModal() {
      this.$refs['add-camper-to-group'].hide()
    },
    async setCamperToGroup(bvModalEvt) {
      bvModalEvt.preventDefault()

      // eslint-disable-next-line consistent-return
      return new Promise(() => {
        this.$refs.addCamperToGroupRules.validate().then(success => {
          if (success) {
            this.$emit('ok', {
              member: this.chosenMember,
              sessions: this.chosenSessions.map(session => session.id),
            })
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">

</style>
