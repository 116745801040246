export default function useFormBuilder() {
  const fieldTypes = {
    SHORT_TEXT: 'short_text',
    LONG_TEXT: 'long_text',
    EMAIL: 'email',
    NUMBER: 'number',
    PHONE: 'phone',
    DATE: 'date',
    DROPDOWN: 'dropdown',
    MULTI_SELECT: 'multi_select',
    CHECKBOX: 'checkbox',
    RADIO_BUTTON: 'radio_button',
    STAFF: 'staff',
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fieldTypes,
  }
}
