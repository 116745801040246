<template>
  <div>

    <group-type-limit-selection-condition
      v-for="(item, index) in conditionsList"
      :key="index"
      class="mt-2"
      :condition-data="item"
      :index="index"
      :custom-fields-list="customFieldsList"
      :student-ppa-list="studentPpaList"
      :operators-options="operatorsOptions"
      @removeConditionItem="removeConditionItem"
    />

    <div
      v-if="conditionsList.length > 0"
      class="d-flex align-items-center my-2"
    >
      <feather-icon
        icon="AlertCircleIcon"
        size="25"
        class="mr-1 flex-shrink-0 text-warning"
      />
      <div>
        For this condition to function properly, please ensure the form field with this PPA is placed before prompting the applicant to select this group.
      </div>
    </div>

    <b-button
      variant="outline-primary"
      class="d-flex align-items-center justify-content-center w-100"
      :disabled="customFieldsList.length == 0"
      @click="addNewConditionItem"
    >
      <feather-icon
        icon="PlusIcon"
        size="18"
        class="mr-1"
      />
      Add Condition
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

import { mapGetters } from 'vuex'
import GroupTypeLimitSelectionCondition from '@/views/admin/group/group-forms/components/GroupTypeLimitSelectionConditionItem.vue'
import useAutoAssigns from '@/views/admin/group/group-forms/components/useAutoAssigns'
import useLimitSelection from '@/views/admin/group/group-forms/components/useLimitSelection'

export default {
  components: {
    BButton,

    GroupTypeLimitSelectionCondition,
  },
  props: {
    conditionsList: {
      type: Array,
      default: () => {
      },
    },
    customFieldsList: {
      type: Array,
      default: () => {
      },
    },
  },
  data() {
    return {
      studentPpaList: [],
      operatorsOptions: [],
    }
  },
  setup() {
    const {
      fetchPpaList,
    } = useAutoAssigns()
    const {
      fetchOperatorsList,
    } = useLimitSelection()

    return {
      fetchPpaList,
      fetchOperatorsList,
    }
  },
  computed: {
    ...mapGetters({
      programId: 'verticalMenu/getDefaultProgram',
    }),
  },
  async created() {
    this.studentPpaList = await this.fetchPpaList({ program_id: this.programId })
    await this.setOperatorsOptions()
  },
  methods: {
    async setOperatorsOptions() {
      const response = await this.fetchOperatorsList()
      this.operatorsOptions = response.reduce((arr, item) => {
        arr.push({
          value: item,
          label: item.split('_').join(' '),
        })
        return arr
      }, [])
    },
    addNewConditionItem() {
      this.conditionsList.push({
        studentPpa: null,
        operator: null,
        customField: null,
      })
    },
    removeConditionItem(index) {
      this.conditionsList.splice(index, 1)
    },
  },
}
</script>
