import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useLimitSelection() {
  // Use toast
  const toast = useToast()

  const fetchOperatorsList = queryParams => axios
    .get('auth/group-types-available-limit-selection-conditions', { params: queryParams })
    .then(response => response.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching operators list',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  return {
    fetchOperatorsList,
  }
}
