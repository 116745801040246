import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useGroupMembers(root) {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)
  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])

  // Table Handlers
  const tableColumns = [
    { key: 'show_details', label: '#' },
    { key: 'name', label: 'Group', sortable: true },
    { key: 'program', label: 'Program', sortable: false },
    { key: 'member_count', label: 'Members', sortable: true },
    { key: 'type_name', label: 'type', sortable: true },
    { key: 'status', sortable: true },
    { key: 'has_tuition_fee', label: 'Tuition/Fee', sortable: true },
    { key: 'actions' },
  ]

  const defaultPerPage = Number(localStorage.getItem('groupsPerPage'))
  const perPage = ref(defaultPerPage || DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const filterStatusOptions = ['Active', 'Inactive']
  const searchQuery = ref('')
  const filterDataType = ref('')
  const filterDataStatus = ref('')
  const filterDataSession = ref('')
  const filterDataTuition = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const expandAll = ref(true)
  let debounceTimeout = ref(null)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const clearFilters = () => {
    filterDataType.value = ''
    filterDataStatus.value = ''
    filterDataSession.value = ''
    filterDataTuition.value = ''
  }

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch(programId, () => {
    refetchData()
  })

  watch([currentPage, perPage, searchQuery, sortBy, filterDataType, filterDataStatus, filterDataSession, filterDataTuition],
    ([currentPageVal, perPageVal, searchQueryVal, sortByVal, filterDataTypeVal, filterDataStatusVal, filterDataSessionVal, filterDataTuitionVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal, sortByPrevVal, filterDataTypePrevVal, filterDataStatusPrevVal, filterDataSessionPrevVal, filterDataTuitionPrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'admin-groups', 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'admin-groups', 'perPage', perPageVal)
      }
      if (sortByVal !== sortByPrevVal) {
        pushStateFiltersParams(root, 'admin-groups', 'sortBy', sortByVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'admin-groups', 'searchQuery', searchQueryVal)
        }, 250)
      }
      if (filterDataTypeVal !== filterDataTypePrevVal) {
        pushStateFiltersParams(root, 'admin-groups', 'filterDataType', filterDataTypeVal)
      }
      if (filterDataStatusVal !== filterDataStatusPrevVal) {
        pushStateFiltersParams(root, 'admin-groups', 'filterDataStatus', filterDataStatusVal)
      }
      if (filterDataSessionVal !== filterDataSessionPrevVal) {
        pushStateFiltersParams(root, 'admin-groups', 'filterDataSession', filterDataSessionVal)
      }
      if (filterDataTuitionVal !== filterDataTuitionPrevVal) {
        pushStateFiltersParams(root, 'admin-groups', 'filterDataTuition', filterDataTuitionVal)
      }
    })

  const fetchGroupList = (ctx, callback) => {
    expandAll.value = false
    const query = { ...root.$route.query }
    const payloadData = {
      exceptProgramType: 'Program',
      perPage: perPage.value,
      page: query.currentPage || 1,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      programId: programId.value,
    }

    if (searchQuery.value) {
      payloadData.name = searchQuery.value
    }
    if (filterDataStatus.value) {
      payloadData.status = filterDataStatus.value
    }
    if (filterDataType.value && filterDataType.value !== 'All') {
      payloadData.typeId = filterDataType.value
    }
    if (filterDataSession.value) {
      payloadData.sessionId = filterDataSession.value
    }
    if (filterDataTuition.value) {
      payloadData.hasTuition = filterDataTuition.value === 'Tuition' ? 1 : 0
    }
    store
      .dispatch('app-group/fetchGroupList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta

        // eslint-disable-next-line no-return-assign, no-underscore-dangle, no-param-reassign
        data.forEach(item => item._showDetails = false)
        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching grups' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const postGroupType = queryParams => axios
    .post('/auth/group-types', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Group Type was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error creating Group Type',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error.response
    })

  const fetchGroupsUsers = queryParams => axios
    .get('/auth/groups-users', { params: queryParams })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: "Error fetching Groups' Users",
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status.toLocaleString() === 'Active') return 'info'
    if (status.toLocaleString() === 'Inactive') return 'danger'
    return 'primary'
  }

  const actionOptions = [
    { value: 'edit', title: 'Edit', link: 'groups-detail' },
    { value: 'delete', title: 'Delete Group', link: '' },
  ]

  const groupStatusOptions = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },
  ]

  const filterTuitionFeeOptions = [
    { value: 'Tuition', label: 'Tuition' },
    { value: 'Fee', label: 'Fee' },
  ]

  return {
    fetchGroupList,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    filterDataType,
    filterDataStatus,
    filterDataSession,
    filterDataTuition,
    searchQuery,
    filterStatusOptions,
    sortBy,
    totalItems,
    isSortDirDesc,
    refInvoiceListTable,
    programId,
    expandAll,

    statusFilter,
    actionOptions,
    groupStatusOptions,
    filterTuitionFeeOptions,

    resolveStatusVariant,

    refetchData,
    clearFilters,

    postGroupType,
    fetchGroupsUsers,
  }
}
