<template>
  <b-col
    md="6"
    xl="6"
  >
    <b-form-group
      :label="fieldsData.label"
    >
      <validation-provider
        #default="{ errors }"
        :name="fieldsData.label"
        :rules="fieldsData.required ? 'required' : ''"
      >
        <b-form-input
          v-if="showTextField(fieldsData.type)"
          v-model="fieldsData.value"
          :name="fieldsData.name"
          :disabled="fieldsData.disabled"
          :state="errors.length > 0 ? false : null"
        />

        <b-form-textarea
          v-if="fieldsData.type === 'long_text'"
          id="textarea-default"
          v-model="fieldsData.value"
          :name="fieldsData.name"
          rows="3"
          :disabled="fieldsData.disabled"
          :state="errors.length > 0 ? false : null"
        />

        <b-form-datepicker
          v-if="fieldsData.type === 'date'"
          id="example-datepicker"
          v-model="fieldsData.value"
          class="mb-1"
          :disabled="fieldsData.disabled"
          :state="errors.length > 0 ? false : null"
        />

        <b-form-select
          v-if="fieldsData.type === 'dropdown'"
          v-model="fieldsData.value"
          :options="fieldsData.options"
          :disabled="fieldsData.disabled"
          :class="{'is-invalid': errors.length > 0 }"
        />

        <v-select
          v-if="fieldsData.type === 'multi_select'"
          v-model="fieldsData.value"
          placeholder="Select"
          multiple
          :close-on-select="false"
          :options="fieldsData.options"
          :deselect-from-dropdown="true"
          :class="{'is-invalid': errors.length > 0 }"
        />

        <template
          v-if="fieldsData.type === 'checkbox'"
        >
          <b-form-checkbox-group
            v-model="fieldsData.value"
            :options="fieldsData.options"
            class="py-1"
            :disabled="fieldsData.disabled"
          />
        </template>

        <template
          v-if="fieldsData.type === 'radio_button'"
        >
          <b-form-radio-group
            v-model="fieldsData.value"
            :options="fieldsData.options"
            class="py-1"
            :disabled="fieldsData.disabled"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </template>

        <template
          v-if="fieldsData.type === 'staff'"
        >
          <v-select
            v-model="fieldsData.value"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="fieldsData.options"
            :clearable="true"
            :placeholder="'Select Staff'"
            :class="{'is-invalid': errors.length > 0 }"
          />
        </template>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>

  </b-col>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormRadioGroup,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { formBuilderPreparations } from '@core/mixins/formBuilderPreparations'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,

    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormRadioGroup,

    vSelect,
  },
  mixins: [formBuilderPreparations],
  props: {
    fieldsData: {
      type: Object,
      default: () => {},
    },
    fieldIndex: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    showTextField(type) {
      // eslint-disable-next-line no-bitwise
      return ~['text', 'email', 'tel', 'number'].findIndex(item => item === this.transformField(type))
    },
  },
}
</script>

<style scoped>

</style>
