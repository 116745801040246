import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useBulkCommunicate() {
  // Use toast
  const toast = useToast()

  const bulkCommunicate = queryParams => axios
    .post('auth/communications/bulk-send', queryParams)
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Bulk Communication error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })

  const bulkCommunicateFromReport = queryParams => axios
    .post('auth/communications/reports-bulk-send', queryParams)
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Bulk Communication error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response
    })
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    bulkCommunicate,
    bulkCommunicateFromReport,
  }
}
