<template>
  <b-modal
    id="selectAllModal"
    ref="selectAllModal"
    centered
    title="Choose Select All Mode"
    ok-title="Select"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    hide-header-close
    ok-only
    @ok="selectOption"
  >
    <div>
      <b-form-group
        v-slot="{ ariaDescribedby }"
        label="Mode"
      >
        <b-form-radio
          v-model="selected"
          :aria-describedby="ariaDescribedby"
          name="some-radios"
          value="all"
          class="mb-1"
        >
          Select All in Group
        </b-form-radio>
        <b-form-radio
          v-model="selected"
          :aria-describedby="ariaDescribedby"
          name="some-radios"
          value="onPage"
        >
          Select All on the Page
        </b-form-radio>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BFormRadio,
} from 'bootstrap-vue'

export default {
  name: 'SelectAllModal',
  components: {
    BFormGroup,
    BFormRadio,
  },
  data() {
    return {
      selected: 'all',
    }
  },
  methods: {
    selectOption() {
      this.$emit('selectOption', this.selected)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
