var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"bulkAddToGroupModal"},[_c('b-modal',{ref:"bulk-add-to-group-modal",attrs:{"id":"bulk-add-to-group-modal","centered":"","title":"Bulk Add To Group","ok-title":"Save","ok-only":"","no-close-on-backdrop":""},on:{"hidden":_vm.resetModal,"show":_vm.preparePlaceholdersData},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('span',{staticClass:"mr-1 text-muted"},[_vm._v(_vm._s(_vm.usersListReadyForAdding.length)+" Available Users")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary","disabled":_vm.isLoading},on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","disabled":_vm.isLoading || !_vm.usersListReadyForAdding.length},on:{"click":_vm.handleForm}},[_vm._v(" Save ")])]},proxy:true}])},[_c('b-overlay',{attrs:{"id":"overlay-background","show":_vm.isLoading,"variant":"white","opacity":"1","rounded":"sm"}},[_c('b-row',[(_vm.withExecute)?_c('b-col',{staticClass:"mb-1",attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Field with available application ID"}},[_c('validation-provider',{attrs:{"name":"destination field","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid': errors.length > 0 },attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.destinationTypeOptions,"reduce":function (option) { return option.value; },"clearable":false,"placeholder":'Select Field',"disabled":!_vm.destinationTypeOptions.length},on:{"input":_vm.handleTargetIdSelected},model:{value:(_vm.targetField),callback:function ($$v) {_vm.targetField=$$v},expression:"targetField"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,749305475)})],1)],1):_vm._e(),_c('b-col',{staticClass:"mb-1",attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Group"}},[_c('validation-provider',{attrs:{"name":"group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid': errors.length > 0 },attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.availableGroupsList,"label":"name","value":"id","clearable":false,"placeholder":_vm.fieldGroupStatus,"disabled":!_vm.targetField && _vm.withExecute},on:{"input":_vm.handleGroupSelected},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var type = ref.type;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(_vm._s(name))]),_vm._v(" "),_c('em',{staticClass:"mr-2"},[_vm._v(_vm._s(type))])])]}}],null,true),model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.selectedGroup)?_c('b-col',{staticClass:"mb-1",attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Session"}},[_c('validation-provider',{attrs:{"name":"session","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid': errors.length > 0 },attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.availableSessionsListBySelectedGroup,"multiple":"","label":"name","value":"id","clearable":false,"placeholder":'Select Session',"reduce":function (session) { return session.id; }},on:{"input":_vm.handleSessionSelected},model:{value:(_vm.selectedSession),callback:function ($$v) {_vm.selectedSession=$$v},expression:"selectedSession"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1812894416)})],1)],1):_vm._e(),(_vm.isUsersLoading)?_c('b-col',{staticClass:"mb-1 d-flex justify-content-center align-items-center",attrs:{"sm":"12"}},[_c('span',{staticClass:"text-muted mr-1"},[_vm._v("Fetching Available Users")]),_vm._v(" "),_c('b-spinner')],1):_vm._e(),(_vm.usersListReadyForAdding.length)?_c('b-col',{staticClass:"mb-1",attrs:{"sm":"12"}},[_c('app-collapse',[_c('app-collapse-item',{attrs:{"title":("Available Users (" + (_vm.usersListReadyForAdding.length) + ")")}},_vm._l((_vm.usersListReadyForAdding),function(application){return _c('div',{key:application.id,staticClass:"mb-1"},[_vm._v(" "+_vm._s(application.user.full_name)+" ")])}),0)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }