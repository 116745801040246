import axios from '@axios'
import { computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useBulkGenerateLetter() {
  // Use toast
  const toast = useToast()

  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])

  const fetchProgram = ID => axios
    .get(`/auth/programs/${ID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching program item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchProgramList = queryParams => axios
    .get('auth/programs', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching programs',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const fetchLetterTemplates = queryParams => axios
    .get('auth/letter-templates', {
      params: queryParams,
    })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching letters',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const generateUsersLetters = queryParams => axios
    .post('/auth/users/generate-letter', queryParams, { responseType: 'blob' })
    .then(response => response)
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error generating letter',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error.response
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    programId,
    fetchProgram,
    fetchProgramList,
    fetchLetterTemplates,
    generateUsersLetters,
  }
}
