import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useAutoAssigns() {
  // Use toast
  const toast = useToast()

  const fetchPpaList = queryParams => axios
    .get('/auth/field_keys', { params: queryParams })
    .then(response => response.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching PPA items',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchPpaList,
  }
}
