import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useGroupDetail() {
  // Use toast
  const toast = useToast()

  const addMemberToGroup = queryParams => axios
    .post(`/auth/groups/${queryParams.group_id}/add-users`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Group Member was successfully added',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error adding Group Member',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error.response
    })

  const moveMemberToGroup = queryParams => axios
    .post(`/auth/groups/${queryParams.group_id}/move-users`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Group Member was successfully moved',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error adding Group Member',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const removeMemberFromGroup = queryParams => axios
    .post(`/auth/groups/${queryParams.group_id}/remove-users`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Group Member was successfully removed',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error removing Group Member',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const addApplicationToGroup = queryParams => axios
    .post(`/auth/groups/${queryParams.group_id}/add-applications`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Group Member was successfully added',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error adding Group Member',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error.response
    })

  const moveApplicationToGroup = queryParams => axios
    .post(`/auth/groups/${queryParams.group_id}/move-applications`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Group Member was successfully moved',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error adding Group Member',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const removeApplicationFromGroup = queryParams => axios
    .post(`/auth/groups/${queryParams.group_id}/remove-applications`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Group Member was successfully removed',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error removing Group Member',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const generateUsersLetters = queryParams => axios
    .post('/auth/users/generate-letter', queryParams, { responseType: 'blob' })
    .then(response => response)
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error generating letter',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error.response
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    addMemberToGroup,
    moveMemberToGroup,
    removeMemberFromGroup,
    generateUsersLetters,
    addApplicationToGroup,
    moveApplicationToGroup,
    removeApplicationFromGroup,
  }
}
